import './index.css';


const GetStarted = () => {
    return (
        <div className='get-started-bg-conatiner'>
            Get Started
        </div>
    )
}


export default GetStarted;