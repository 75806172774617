import './index.css';

const UsageGuide = () => {
    return (
        <div className='usage-guide-bg-conatiner'>
           Usage Guide 
        </div>
    )
}


export default UsageGuide;