import './index.css';


const PurchasingQuestions = () => {
    return (
        <div className='purchasing-questions-bg-conatiner'>
            Purchasing Questions
        </div>
    )
}


export default PurchasingQuestions;