import './ManageProjects.css';


const ManageProjects = () => {
    return (
        <div className='manage-projects-bg-container'>
            Manage Projects 
        </div>
    )
}

export default ManageProjects;